import React from "react";
import { Helmet } from "react-helmet";

export default function SingleTestimonial() {
  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto">
          <div class="senja-frame-embed" data-id="70bd5559-6270-472f-a29f-d0a8cc80962f"></div>{" "}
          <Helmet>
            <script
              defer
              type="text/javascript"
              src="https://widget.senja.io/embed/frame.js"
            ></script>
          </Helmet>
        </div>
      </div>
    </>
  );
}
