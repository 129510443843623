/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import SingleTestimonial from "../components/testimonials/Single";
import AddToSlackButton from "../components/buttons/AddToSlack";

const tiers = [
  // {
  //   name: "Free",
  //   priceMonthly: 0,
  //   priceYearly: 0,
  //   label: "Add to Slack",
  //   description: "For small teams, student groups and non-profits",
  //   includedFeatures: [
  //     "Workspaces up to 3 members",
  //     "Unlimited for non-profits and student groups",
  //     "Contact us for access if you are a non-profit company",
  //   ],
  // },
  {
    name: "Seed",
    priceMonthly: 5,
    priceYearly: 50,
    description: "For early startups and other small sized teams",
    url_monthly: "https://meet.projectundefined.com/install?plan=seed",
    url_yearly: "https://meet.projectundefined.com/install?plan=seed-annual",
    includedFeatures: [
      "Workspaces up to 50 members",
      "Start Google Meets by sending /meet in Slack",
      "Enterprise grade data security",
    ],
  },
  {
    name: "Scale",
    priceMonthly: 15,
    priceYearly: 150,
    description: "For established startups and other medium sized teams",
    url_monthly: "https://meet.projectundefined.com/install?plan=scale",
    url_yearly: "https://meet.projectundefined.com/install?plan=scale-annual",
    includedFeatures: [
      "Workspaces up to 150 members",
      "Start Google Meets by sending /meet in Slack",
      "Enterprise grade data security",
      "Priority support",
    ],
  },
  {
    name: "Growth",
    priceMonthly: 50,
    priceYearly: 500,
    description: "For large companies and teams seeking the best",
    url_monthly: "https://meet.projectundefined.com/install?plan=growth",
    url_yearly: "https://meet.projectundefined.com/install?plan=growth-annual",
    sublabel_url_monthly: "/contact",
    sublabel_url_yearly: "/contact",
    // label: "Contact Sales",
    sublabel: "Or contact sales",
    // sublabel_url_monthly: "https://meet.projectundefined.com/install?plan=enterprise",
    // sublabel_url_yearly: "https://meet.projectundefined.com/install?plan=enterprise-annual",
    includedFeatures: [
      "Workspaces up to 500 members",
      "Start Google Meets by sending /meet in Slack",
      "Enterprise grade data security",
      "Top priority support",
      // "Custom privacy agreements",
      // "Support for Slack Enterprises",
    ],
  },
  {
    name: "Enterprise",
    // priceMonthly: null,
    // priceYearly: 2,
    custom: true,
    text: "$2",
    description: "For enterprises and big teams seeking the best",
    url_monthly: "https://meet.projectundefined.com/install?plan=enterprise",
    url_yearly: "https://meet.projectundefined.com/install?plan=enterprise",
    sublabel_url_monthly: "/contact",
    sublabel_url_yearly: "/contact",
    // label: "Contact Sales",
    sublabel: "Or contact sales",
    // sublabel_url_monthly: "https://meet.projectundefined.com/install?plan=enterprise",
    // sublabel_url_yearly: "https://meet.projectundefined.com/install?plan=enterprise-annual",
    includedFeatures: [
      "Workspaces with over 500 members",
      "Start Google Meets by sending /meet in Slack",
      "Enterprise grade data security",
      "Top priority support",
      "Custom privacy agreements",
      // "Support for Slack Enterprises",
    ],
  },
  // {
  //   name: "Enterprise",
  //   custom: true,
  //   text: "Contact us",
  //   description: "For enterprises and big teams seeking the best",
  //   url_monthly: "/contact",
  //   url_yearly: "/contact",
  //   sublabel_url_monthly: "/contact",
  //   sublabel_url_yearly: "/contact",
  //   label: "Contact sales",
  //   // sublabel: "Or contact sales",
  //   // sublabel_url_monthly: "https://meet.projectundefined.com/install?plan=enterprise",
  //   // sublabel_url_yearly: "https://meet.projectundefined.com/install?plan=enterprise-annual",
  //   includedFeatures: [
  //     "Unlimited team size",
  //     "Start Google Meets by sending /meet in Slack",
  //     "Enterprise grade data security",
  //     "Custom privacy agreements",
  //     "Support for Slack Enterprises",
  //     "Dedicated account manager",
  //   ],
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingTable({ location }) {
  const params = new URLSearchParams(location?.search);
  const error = params.get("error");
  const [period, setPeriod] = useState("yearly");
  return (
    <div className="pt-16 overflow-hidden">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
            {error && (
              <div className="rounded-md bg-red-50 p-4 mt-8 mb-8">
                <div className="flex">
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      There was an error with your purchase
                    </h3>
                    <div className="mt-2 text-sm text-red-700">{error}</div>
                  </div>
                </div>
              </div>
            )}
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
              <a href="https://meet.projectundefined.com/install">
                Start with a <span className="font-medium text-indigo-600">free 60 day trial</span>
              </a>
              , no credit card required. Then upgrade based on your team size.
            </p>

            <p className="text-xl text-gray-500 sm:text-center">
              Every team member is a host and can start & join Google Meets.
            </p>

            <p className="mt-4 text-xl text-gray-500 sm:text-center">
              Free for non-profits and teams up to 3 people.
            </p>

            <div className="mt-5 max-w-max mx-auto">
              <AddToSlackButton />
            </div>

            <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
              <button
                type="button"
                onClick={() => setPeriod("monthly")}
                className={classNames(
                  period === "monthly" ? "bg-white border-gray-200" : "",
                  "relative w-1/2 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"
                )}
              >
                Monthly billing
              </button>
              <button
                type="button"
                onClick={() => setPeriod("yearly")}
                className={classNames(
                  period === "yearly" ? "bg-white border-gray-200" : "",
                  "relative w-1/2 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"
                )}
              >
                Yearly billing
              </button>
            </div>
          </div>

          <div className="mt-6 space-y-4 sm:mt-12 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="pt-6 pl-6 pr-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      {tier?.custom
                        ? tier?.text
                        : period === "monthly"
                        ? "$" + tier.priceMonthly
                        : "$" + tier.priceYearly}
                    </span>{" "}
                    <span className="text-base font-medium text-gray-500">
                      {tier?.custom ? "/member per year" : period === "monthly" ? "/month" : "/year"}
                    </span>
                    {/* {period === "yearly" && (
                      <p className="text-base  text-gray-500 mt-2">
                        {tier.priceYearlyProrated == 0
                          ? "Free for up to 10 people"
                          : `Billed yearly ($${tier.priceYearly} per team)`}
                      </p>
                    )} */}
                  </p>
                  <a
                    href={
                      tier.url_monthly
                        ? period === "monthly"
                          ? tier.url_monthly
                          : tier.url_yearly
                        : "https://meet.projectundefined.com/install"
                    }
                    className={`mt-8 block w-full rounded-md py-2 text-sm font-semibold text-white text-center bg-indigo-600 hover:bg-indigo-700 ${
                      tier?.custom && ""
                    }`}
                  >
                    {tier.label ? tier.label : `Buy ${tier.name}`}
                  </a>

                  <div className="text-center">
                    {tier.sublabel ? (
                      <div className="m-2 h-6">
                        <a
                          href={
                            tier.sublabel_url_monthly
                              ? period === "monthly"
                                ? tier.sublabel_url_monthly
                                : tier.sublabel_url_yearly
                              : "https://meet.projectundefined.com/install"
                          }
                          className="text-sm text-gray-500"
                        >
                          {tier.sublabel}
                        </a>
                      </div>
                    ) : (
                      <div className="h-10"></div>
                    )}
                  </div>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="mt-8">
            <p className="text-lg text-gray-500 font-medium sm:text-center">
              Meet Slack is <span className="text-indigo-600">free for non-profits</span> and teams
              under 10 people.
            </p>
          </div> */}
          <div className="pt-6">
            <SingleTestimonial />
          </div>
        </div>
      </div>
    </div>
  );
}
