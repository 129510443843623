import * as React from "react";
import { Helmet } from "react-helmet";

import SEO from "../components/seo/SEO";

import Layout from "../components/layout/Layout";
import PricingTable from "../components/PricingTable";
import Testimonials from "../components/testimonials/Testimonials";

const PricingPage = ({ location }) => (
  <Layout>
    <SEO />
    <Helmet>
      <title>Pricing - /meet 4 Slack</title>
    </Helmet>
    <PricingTable location={location} />
  </Layout>
);

export default PricingPage;
